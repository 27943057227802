<template>
    <div class="outstock-page">
        <div class="head-box">
            <p class="title">商品出库管理</p>
            <Select v-model="supplierId" filterable style="width:200px">
                <Option v-for="item in suppliers" :value="item.supplierId" :key="item.supplierId">{{ item.supplierName }}</Option>
            </Select>
        </div>
        <div class="container outstock-container">
            <div class="menu" v-if="menus && menus.length > 0">
                <Menu theme="light" :active-name="currentMenu" @on-select="onChangeMenu">
                    <MenuItem v-for="(item, idx) in menus" :name="item.id" :key="'menu-' + idx">
                        <Icon type="ios-information-circle" />
                        {{ item.title }}
                    </MenuItem>
                </Menu>
            </div>
            <div class="content" v-if="currentGoods">
                <div class="title">待出库商品:</div>
                <div class="goods-box">
                    <div class="item-box" v-for="(item, idx) in currentGoods" :key="idx">
                        <div class="picture-box">
                            <img class="img" :src="item.goodsThumb" alt="" />
                        </div>
                        <div class="info-box">
                            <div class="name-box">{{ item.goodsName }}</div>
                            <div class="specification">{{ item.goodsSp }}</div>
                        </div>
                        <div class="quantity">x{{ item.goodsNum }}</div>
                    </div>
                </div>
                <div class="submit-base">
                    <div class="item-box">当前自提点出库</div>
                    <div class="item-box" @click="onSubmitAll">全部出库</div>
                </div>
            </div>

            <div class="empty-box" v-if="menus && menus.length <= 0">
                <Icon type="md-alert" size="30" />
                <p class="title">当前无需出库，当用户下单后即可查看</p>
            </div>
        </div>
    </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"

export default {
    components: { LiefengModal },

    data() {
        return {
            // 数据列表
            menus: null,
            // 当前活动的菜单
            currentMenu: null,
            // 当前商品列表
            currentGoods: null,
            // 当前供应商
            supplierId: null,
            // 供应商列表
            suppliers: null,
        }
    },

    watch: {
        currentMenu(val) {
            if (val == null) {
                return (this.currentGoods = [])
            }

            for (let i = 0, l = this.list; i < l.length; i++) {
                if (val === l[i].id) {
                    this.currentGoods = l[i].goodsStatList
                    return
                }
            }
        },

        supplierId() {
            this.getOutStockGoods()
        },
    },

    created() {
        this.deadline = Math.floor(new Date().getTime() / 1000)
        // 获取供应商列表
        this.getSupplierList()
    },

    methods: {
        onChangeMenu(evt) {
            this.currentMenu = evt
        },

        /**
         * 获取出库商品
         */
        getOutStockGoods() {
            this.$get("/gateway/apps/o2o/api/o2o/admin/listSupplierOrderByTerminal", {
                supplierId: this.supplierId,
                payTime: this.deadline,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                staffName: parent.vue.loginInfo.userinfo.realName,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.dataList
                    const menus = []

                    for (let i = 0; i < data.length; i++) {
                        let id = this.$core.randomString()

                        menus.push({
                            id: id,
                            title: data[i].groupName,
                        })

                        data[i].id = id
                    }

                    if (menus.length > 0) {
                        this.currentMenu = menus[0].id
                    } else {
                        this.currentMenu = null
                    }

                    this.menus = menus
                    this.list = data
                } else {
                    this.$Message.error("请求失败，错误信息：" + res.desc)
                }
            })
        },

        /**
         * 获取供应商列表
         */
        getSupplierList() {
            this.$get("/gateway/apps/o2o/api/o2o/user/selectUserHasSupplier", {
                oemCode: parent.vue.oemInfo.oemCode,
            }).then(res => {
                if (res.code == 200) {
                    const data = res.dataList
                    // 供应商列表
                    this.suppliers = data

                    if (data && data.length > 0) {
                        // 默认第一个
                        this.supplierId = data[0].supplierId
                    }
                }
            })
        },

        // 全部出库
        onSubmitAll() {
            this.$post("/gateway/apps/o2o/api/o2o/admin/recordSupplierOutWarehouse", {
                supplierId: this.supplierId,
                payTime: this.deadline,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                staffName: parent.vue.loginInfo.userinfo.realName,
            }).then(res => {
                if (res.code == 200) {
                    this.$Message.success("出库成功！")
                    // 刷新数据
                    this.getOutStockGoods()
                } else this.$Message.error(res.desc)
            })
        },
    },
}
</script>

<style lang="less">
.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.outstock-page {
    height: 100%;
    display: flex;
    flex-direction: column;

    .head-box {
        width: 100%;
        height: 50px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        border-bottom: 1px solid #e3e3e3;

        .title {
            font-size: 18px;
            font-weight: bold;
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .menu {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .ivu-menu {
            height: 100%;
            width: 220px !important;
        }
    }
    .content {
        overflow: auto;
        flex: 1;
        height: 100%;
        padding: 10px 20px;
    }
}

/deep/ .validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #22a96c;
    }
}

/deep/ #modal_contentarea {
    overflow: inherit !important;
}

.outstock-container {
    .content {
        overflow: auto;

        .title {
            font-size: 22px;
            line-height: 50px;
            margin-left: 10px;
        }

        .submit-base {
            position: absolute;
            bottom: 20px;
            right: 20px;
            display: flex;

            .item-box {
                padding: 10px 20px;
                border-radius: 8px;
                background: #2faaf7;
                color: #fff;
                display: flex;
                margin-right: 15px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                transition: all 0.2s;
                cursor: pointer;

                &:hover {
                    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .goods-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 70px;

        .item-box {
            width: 300px;
            padding: 10px;
            margin: 7px;
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
            display: flex;
            align-items: center;
            transition: all 0.2s;

            &:hover {
                box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.08);
            }

            .picture-box {
                width: 50px;
                height: 50px;
                flex-shrink: 0;

                .img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
            }

            .info-box {
                margin: 0 10px;
                flex-grow: 1;
                height: 90%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .name-box {
                    .ellipsis;
                }

                .specification {
                    font-size: 12px;
                    color: #888;

                    .ellipsis;
                }
            }

            .quantity {
                font-size: 16px;
                color: #333;
                flex-shrink: 0;
            }
        }
    }

    .empty-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            margin-top: 10px;
        }
    }
}
</style>
